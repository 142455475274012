/* You can add global styles to this file, and also import other style files */

html, body { height: 100%;   background: #f4f3f3; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


/*for align social icons at manage user page */

  .mat-fab .mat-button-wrapper {
  line-height: 10px;
  padding: 10px 0px !important;
}

/*for font awesome as mentioned in angular sharebuttom package */
@import '~ngx-sharebuttons/themes/default/default-theme';

.mat-sidenav {
	/*background-color: #3d453f;*/
  background-color: #222222;
}
.mat-list-base .mat-list-item { 
	color: #f4f3f3;
}

.mat-toolbar {
	color: #f4f3f3;
	background-color: #222222;
}

.mat-divider {
	background-color: #000;
}

/*mat-sidenav {
  width: 200px;
}*/



.mat-list-base .mat-list-item .mat-list-item-content, .mat-list-base .mat-list-option .mat-list-item-content {
	display: block !important;
}

.example-footer {
  position: absolute;
  bottom: 0;/*
  left: 0;
  right: 0;*/
}

/*to make bacground purple all pages*/
.main-view {
 /* background-color: #aad6cc;*/
  /*padding: 4%;*/

  min-height: 1000px;
}

.banner {
   background: #aad6cc;
  height: 325px;
  position: relative;
}

.overlaying {
   /*  background: #f4f3f3;*/
    /*margin-top: -150px;*/
    margin-top: -12%;
    /*position: relative;*/
    z-index: 1;
}

/*.mat-input-element {
    caret-color: #673ab7;
}
*/
.main-padding {
  padding: 0px 20px;
}

.mat-standard-chip {
  color: #f4f3f3 !important;
  background-color: #55a8a3 !important;

}

.mat-progress-spinner circle, .mat-spinner circle {
    stroke: #55a8a3 !important;
}

.mat-progress-spinner {
   display: initial !important;
   margin: 0 auto !important;
   z-index: 1;
     top: 70px;
  left: 50%;

}

.primary-back-colour {
   background: #55a8a3 !important;
}
.primary-text-colour {
    color: #f4f3f3 !important;
}

/*style heading  all pages*/
.mat-h1 {
  color: #f4f3f3 !important;
  display: flex;
  margin-top: 26px !important;
}

.for-align {
   padding-left: 0px !important;
}

.mat-card-header-text {
    margin: 0px !important;
    width: 100% !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #55a8a3 !important;
}
.mat-input-element {
  caret-color:#55a8a3 !important;
}
.mat-form-field-appearance-legacy.mat-form-field-invalid:not(.mat-focused) .mat-form-field-ripple {
  height: 0px !important;
}
.mat-form-field-underline {
  display: none !important;
}
 .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0px !important;
  }

.mat-form-field-appearance-legacy .mat-form-field-label {
    top: 3em;
    left: 1.2em;
}

.custom-input {
   border-radius: 25px !important;
   /*border: 2px solid #55a8a3 !important;*/
    padding: 20px !important;/*
    width: 75% !important; */
 /*   height: 15px !important;*/
    margin: 0 auto;
    display: block;
    box-shadow: 0 5px 30px 0 rgb(0 0 0 / 10%) !important;

}

.mat-input-element {
  box-sizing: border-box !important;
}
.login_card {
    text-align: center;
    display: block;
    width: 890px;
    min-height: 520px;
    background-color: #fff;
    box-shadow: 0px 2px 30px #ccc6;
    margin: auto;
    position: relative;
    z-index: 1;
    margin-top: 7%;
    overflow: hidden;
}
.login_frame {
    width: 500px;
    min-height: 520px;
    height: auto;
    background: #fff;
    box-sizing: border-box;
    padding: 25px 50px;
/*    border-radius: 2px;
    transition: all .1s ease-in-out;
    float: left;
    overflow-y: auto;
    display: table-cell;
    border-right: 2px solid #f1f1f1;*/
}
.login-detail {
  width: 300px;
}
.namelogo {
  background: url('https://spoorit.evoxyz.tech/assets/logoname.png') no-repeat center;
      height: 50px;
    background-size: contain;
    margin-bottom: 10%;
}
.logo {
   background: url('https://spoorit.evoxyz.tech/assets/logo.png') no-repeat center;
      height: 50px;
    background-size: contain;
        position: relative;
   /* left: -15%;*/
    float: left;
}
.logo_name {
  height: 18px;
  margin-top: 16%;
}
.company{
  width: 100%;
  margin-top: 5%;

}
.image_frame {
   /* width: 390px;*/
    height: auto;
    float: right;
    box-sizing: border-box;
    padding: 40px;
    background-color: #fff;
    display: table-cell;
}
.login_img {
    display: block;
    height: 200px;
    width: auto;
    margin: 0px 30px 20px 30px;
    background-size: 96%;
}

.text-red {
  color: red;
}
.text-green {
  color: green;
}
/*.custom-input  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    border-radius: 20px;
    box-shadow: 0 5px 30px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 5px 30px 0 rgba(0,0,0,.1);
    -webkit-box-shadow: 0 5px 30px 0 rgb(0 0 0 / 10%);
    -o-box-shadow: 0 5px 30px 0 rgba(0,0,0,.1);
    -ms-box-shadow: 0 5px 30px 0 rgba(0,0,0,.1);
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
}*/
@media (max-width: 768px) {
  .login_card {
    width: 656px;
  }
 .login-detail {
  display: none;
 }
 .login_frame {
  width:100%;
 }
}
@media (max-width: 600px) {
.banner {
  height: 200px;
}
.mat-h1 {
  margin-top: 56px !important;
}
}
@media (max-width: 425px) {
  .login_card {
    width: 316px;
  }
}

@media (max-width: 375px) {
  .login_card {
    width: 268px;
  }
}